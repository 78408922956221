exports.components = {
  "component---src-pages-404-de-jsx": () => import("./../../../src/pages/404.de.jsx" /* webpackChunkName: "component---src-pages-404-de-jsx" */),
  "component---src-pages-404-en-jsx": () => import("./../../../src/pages/404.en.jsx" /* webpackChunkName: "component---src-pages-404-en-jsx" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-404-ru-jsx": () => import("./../../../src/pages/404.ru.jsx" /* webpackChunkName: "component---src-pages-404-ru-jsx" */),
  "component---src-pages-index-de-jsx": () => import("./../../../src/pages/index.de.jsx" /* webpackChunkName: "component---src-pages-index-de-jsx" */),
  "component---src-pages-index-en-jsx": () => import("./../../../src/pages/index.en.jsx" /* webpackChunkName: "component---src-pages-index-en-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-index-ru-jsx": () => import("./../../../src/pages/index.ru.jsx" /* webpackChunkName: "component---src-pages-index-ru-jsx" */)
}

